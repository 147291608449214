import React, { useContext, useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { MyContext } from '../../../router/router';
import backgroundImage from '../../../img/background.png';
import TitleColumn from '../../Title/Column';
import { BaseTextField, MailTextField, PhoneTextField } from "reidea-common";
import dayjs, { Dayjs } from 'dayjs';
import axios from "axios";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import SendButton from '../../Button/sendButton';

const Contact: React.FC = () => {
    const { subTitleFontSize, mainFontSize, contentRatio, isXS, isSmall } = useContext(MyContext);
    const [sei, setSei] = useState('');
    const [mei, setMei] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneError, setPhoneError] = useState<boolean>(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState<boolean>(false);
    const [others, setOthers] = useState('');
    const [date1, setDate1] = useState<Dayjs | null>(null);
    const [date2, setDate2] = useState<Dayjs | null>(null);
    const [date3, setDate3] = useState<Dayjs | null>(null);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setIsLoading] = useState(false);

    const checkFormValidity = (): void => {
        const isValid = !!(
            sei &&
            sei.trim() !== '' &&
            mei &&
            mei.trim() !== '' &&
            email &&
            email.trim() !== '' &&
            !emailError &&
            others &&
            others.trim() !== ''
        );
        setIsFormValid(isValid);
    };

    useEffect(() => {
        checkFormValidity();
    }, [sei, mei, email, others]);

    const handleSendClick = async () => {
        try {
            setOpen(true);
            setIsLoading(true);

            const formData = {
                sei,
                mei,
                phoneNumber,
                email,
                others,
                date1: date1 ? date1.toISOString() : null,
                date2: date2 ? date2.toISOString() : null,
                date3: date3 ? date3.toISOString() : null,
            };

            await axios.post("http://localhost:5001", formData)
            // await axios.post("https://api.kaigai-kurafan.com", formData);

            setTimeout(() => {
                setIsLoading(false);
                setSei(''); setMei('');
                setPhoneNumber(''); setEmail('');
                setOthers('');
                setDate1(null); setDate2(null); setDate3(null);
            }, 2500);

        } catch (err) {
            console.error("エラー:", err);
            setIsLoading(false);
            alert("フォームの送信中にエラーが発生しました。もう一度お試しください。");
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const _isMobile = isXS || isSmall;

    return (
        <Stack id={"contact"} position={"relative"} direction={"column"} width={"100%"} minWidth={"100%"} alignItems={"center"} py={_isMobile ? 8 : 15}>
            <Stack component="img" alt='background dot' position={"absolute"} zIndex={0} top={0} src={backgroundImage} width={"100%"} height={"100%"} sx={{ opacity: 0.2, objectFit: "cover", color: "white" }} />

            <TitleColumn title='無料ご相談' title_en='CONTACT' fontSize={subTitleFontSize + 5} />
            <Stack zIndex={2} mt={4} width={isXS ? "90%" : `${contentRatio * 100 - 25}%`} justifyContent={"center"} spacing={2}>
                <Stack direction={"row"} width={"100%"} justifyContent={"space-between"}>
                    <Stack width={"49%"}>
                        <Typography sx={{ fontSize: mainFontSize, color: "#202020" }}>姓 *</Typography>
                        <BaseTextField border='1px solid lightgrey' borderRadius={2} placeholder='例 : 山田' value={sei} setValue={setSei} hovered_backgroundColor='#F5FBFE' backgroundColor='#F5FBFE' />
                    </Stack>
                    <Stack width={"49%"}>
                        <Typography sx={{ fontSize: mainFontSize, color: "#202020" }}>名 *</Typography>
                        <BaseTextField border='1px solid lightgrey' borderRadius={2} placeholder='例 : 太郎' value={mei} setValue={setMei} hovered_backgroundColor='#F5FBFE' backgroundColor='#F5FBFE' />
                    </Stack>
                </Stack>

                <Stack direction={"row"} width={"100%"} justifyContent={"space-between"}>
                    <Stack width={"49%"}>
                        <Typography sx={{ fontSize: mainFontSize, color: "#202020" }}>電話番号</Typography>
                        <PhoneTextField border='1px solid lightgrey' borderRadius={2} placeholder='例 : 01234-456-789' value={phoneNumber} setValue={setPhoneNumber} hovered_backgroundColor='#F5FBFE' backgroundColor='#F5FBFE' />
                    </Stack>
                    <Stack width={"49%"}>
                        <Typography sx={{ fontSize: mainFontSize, color: "#202020" }}>メールアドレス *</Typography>
                        <MailTextField border='1px solid lightgrey' borderRadius={2} placeholder='例 : abc@example.com' error={emailError} setError={setEmailError} value={email} errorText={"メールアドレスの形式が正しくありません。例 : example@domain.com"} setValue={setEmail} hovered_backgroundColor='#F5FBFE' backgroundColor='#F5FBFE' />
                    </Stack>
                </Stack>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DateTimePicker']}>
                        <Stack>
                            <Typography sx={{ fontSize: mainFontSize, color: "#202020" }}>無料相談希望日時</Typography>
                            <Stack pt={isXS ? 2 : 2} direction={isXS ? "column" : "row"} spacing={isXS ? 1 : undefined} justifyContent={"space-between"}>
                                <DateTimePicker
                                    sx={{ width: isXS ? "100%" : "33%", backgroundColor: '#F5FBFE' }}
                                    label="第1希望"
                                    value={date1}
                                    format="YYYY/MM/DD HH:mm"
                                    onChange={(newValue: Dayjs | null) => setDate1(newValue)}
                                />
                                <DateTimePicker
                                    sx={{ width: isXS ? "100%" : "33%", backgroundColor: '#F5FBFE' }}
                                    label="第2希望"
                                    format="YYYY/MM/DD HH:mm"
                                    value={date2}
                                    onChange={(newValue: Dayjs | null) => setDate2(newValue)}
                                />
                                <DateTimePicker
                                    sx={{ width: isXS ? "100%" : "33%", backgroundColor: '#F5FBFE' }}
                                    label="第3希望"
                                    value={date3}
                                    format="YYYY/MM/DD HH:mm"
                                    onChange={(newValue: Dayjs | null) => setDate3(newValue)}
                                />
                            </Stack>
                        </Stack>
                    </DemoContainer>
                </LocalizationProvider>

                <Stack>
                    <Typography sx={{ fontSize: mainFontSize, color: "#202020" }}>ご相談内容 *</Typography>
                    <BaseTextField border='1px solid lightgrey' borderRadius={2} rows={4} maxRows={4} placeholder='クラウドファンディングに掲載したい商品の説明を簡単にご記入ください。' value={others} setValue={setOthers} hovered_backgroundColor='#F5FBFE' backgroundColor='#F5FBFE' />
                </Stack>
            </Stack>
            <Stack pt={3} width={"30%"}>
                <SendButton
                    title='送信'
                    isDisable={!isFormValid}
                    open={open}
                    onClick={handleSendClick}
                    onClose={handleClose}
                    isLoading={loading}
                />
            </Stack>
        </Stack>
    );
};

export default Contact;