import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { MyContext } from '../../router/router';
import titleBannerImage from  '../../img/titleBanner.png'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import YouTube from 'react-youtube';


interface Props {
    videoid:string;
    title:string;
    description: string;
    fontSize: number;
}

// コンテストのミニカルーセルを実装
const YoutubeCard: React.FC<Props> = (props) => {
    const { titleFontSize, subTitleFontSize, mainFontSize, headerSize, contentRatio, isXS, isSmall, isLarge, isMedium } = useContext(MyContext);

    const width = isXS ? "100%" :
                  isSmall ? 280 :
                  isMedium ? 400 :
                  isLarge ? 450 : 0

    // propsでwidthを渡せないとかいう極上のバグがあったので、is??で直接指定
    const opts = {
        width: width,
        height: typeof width === 'string' ? undefined : width * (3 / 6),
        playerVars: {
          autoplay: 0,
        },
      };

    const _isMobile = isXS || isSmall;
    return (
        <Stack spacing={2} direction={isXS ? "column" : "row"} alignItems={"center"} boxShadow={'0px 4px 8px rgba(0, 0, 0, 0.2)'} width={"100%"} p={_isMobile ? 2 : 4} borderRadius={"10px"} border={"1px solid #383E86"} >
            <YouTube videoId={props.videoid} opts={opts} />
            <Stack height={opts.height} spacing={_isMobile ? 1 : 2}>
                <Typography color={"#202020"} textAlign={"left"} fontWeight={600} fontSize={props.fontSize+6} letterSpacing={1.2} >
                    {props.title}
                </Typography>
                <Typography color={"#202020"} textAlign={"left"} fontWeight={300} fontSize={props.fontSize} letterSpacing={1.2} >
                    {props.description}
                </Typography>
            </Stack>
        </Stack>
    );
};

export default YoutubeCard;